<template>
  <v-container id="logs-errores" fluid tag="section">
    <v-card flat class="px-5">
      <v-row class="mb-3">
        <!-- Fecha inicio -->
        <v-col cols="12" sm="2">
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedStartDate"
                label="Fecha inicio"
                single-line
                hide-details
                outlined
                filled
                dense
                rounded
                class="filtros-trans"
                v-bind="attrs"
                v-on="on"
                @click:append="clearStartDate"
                :append-icon="startDate ? 'mdi-close' : 'mdi-menu-down'"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              :max="today"
              no-title
              scrollable
              locale="es"
              @change="startDateMenu = false"
            />
          </v-menu>
        </v-col>

        <!-- Fecha fin -->
        <v-col cols="12" sm="2">
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedEndDate"
                label="Fecha fin"
                single-line
                hide-details
                outlined
                filled
                dense
                rounded
                class="filtros-trans"
                v-bind="attrs"
                v-on="on"
                @click:append="clearEndDate"
                :append-icon="endDate ? 'mdi-close' : 'mdi-menu-down'"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              :max="today"
              :min="startDate"
              no-title
              scrollable
              locale="es"
              @change="endDateMenu = false"
            />
          </v-menu>
        </v-col>

        <v-col cols="12" sm="4" class="d-flex align-center">
          <v-btn color="primary" small @click="loadLogs">
            Buscar
            <v-icon right>mdi-magnify</v-icon>
          </v-btn>
<!--
          <v-switch
            v-model="showErrorsOnly"
            label="Filtrar errores"
            color="primary"
            :false-value="false"
            :true-value="true"
            dense
            hide-details
            class="ml-4 small-switch"
          ></v-switch>-->
        </v-col>

        <!-- Botón Descargar -->
        <v-col class="d-flex justify-end pt-5">
          <v-btn color="green" small dark @click="exportExcel">
            <v-icon left>mdi-microsoft-excel</v-icon>
            Descargar
          </v-btn>
        </v-col>
      </v-row>

      <!-- Tabla -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="logsErrores"
            class="elevation-0"
          >
            <template v-slot:no-data>
              <div v-if="loading" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mx-auto"
                ></v-progress-circular>
              </div>
              <div v-else>
                Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores
                <v-icon right>mdi-magnify</v-icon>
              </div>
            </template>

            <template #item.url="{ item }">
              <div class="wrap-text">{{ item.url }}</div>
            </template>

            <template #item.status="{ item }">
              <div
                class="status-chip"
                :class="item.status < 400 ? 'status-ok' : 'status-error'"
              >
                {{ item.status }}
              </div>
            </template>


            <template #item.request="{ item }">
              <v-btn color="primary" small @click="editItem('request', item.id)">
                Ver
              </v-btn>
            </template>

            <template #item.response="{ item }">
              <v-btn color="primary" small @click="editItem('response', item.id)">
                Ver
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- Diálogo Detalle -->
    <v-dialog v-model="dialogOpen" max-width="900px">
      <v-card class="rounded-dialog dialog-container">
        <v-card-title
          class="blue darken-2 white--text d-flex align-center"
          style="justify-content: flex-start; align-items: center; height: 50px; padding-bottom: 50px;"
        >
          <span class="display-2"> {{ dialogTipo.charAt(0).toUpperCase() + dialogTipo.slice(1) }}</span>
        </v-card-title>

        <v-card-title
          class="d-flex align-center justify-space-between px-6"
          style="height: 56px;"
        >

          <v-btn color="grey" dark small @click="copyToClipboard">
            Copiar
          </v-btn>
        </v-card-title>

        <v-card-text class="dialog-content">
          <pre style="white-space: pre-wrap;">{{ dialogContent }}</pre>
        </v-card-text>

        <v-card-actions class="dialog-footer">
          <v-btn color="primary" dark @click="dialogOpen = false">
            Entendido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LogErrores",
  data() {
    return {
      loading: false,
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      today: new Date().toISOString().substr(0, 10),
      showErrorsOnly: true,
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "URL", value: "url", sortable: false },
        { text: "Método", value: "method", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Fecha", value: "date", sortable: false },
        { text: "Usuario", value: "logs_user", sortable: false },
        { text: "Ip",  value: "ip",sortable: false },
        { text: "Dispositivo", value: "dispositivo", sortable: false },
        /*{ text: "Nombre", value: "name", sortable: false },*/
        { text: "Request", value: "request", sortable: false },
        { text: "Response", value: "response", sortable: false },
      ],



      logsErrores: [],
      dialogOpen: false,
      dialogContent: "",
      dialogTipo :""
    };
  },
  computed: {
    ...mapState(["user"]),
    /*filteredLogs() {
      if (!this.showErrorsOnly) {
        return this.logsErrores;
      }
      return this.logsErrores.filter(log => log.status >= 400);
    },*/
    formattedStartDate() {
      return this.startDate ? this.$formatDate(this.startDate) : "";
    },
    formattedEndDate() {
      return this.endDate ? this.$formatDate(this.endDate) : "";
    },
  },
  methods: {
    ocultarMenu(){
      setTimeout(() => {
        this.$hideMenu();
      }, 100);
    },
    clearStartDate() {
      this.startDate = null;
    },
    clearEndDate() {
      this.endDate = null;
    },
    copyToClipboard() {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(this.dialogContent).then(() => {
          console.log("Texto copiado con éxito.");
        }).catch(err => {
          console.error("Error al copiar: ", err);
        });
      } else {
        const el = document.createElement("textarea");
        el.value = this.dialogContent;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
    },
    showDialog(type,id) {


      this.dialogContent = content || "";
      this.dialogOpen = true;
    },


    async editItem(tipo,id) {
      Swal.alertGetInfo("Obteniendo información <br><b></b>");

      this.dialogTipo =tipo;

      try {
        console.log(this.$axiosApi.routes["auditShow"]);
        let url =this.$axiosApi.routes["auditShow"] + "/"+id;
        const r = await this.$axiosApi.sendRequest(url,"GET");

        this.dialogContent = r.data.data[tipo] || "";
      this.dialogOpen = true;

        Swal.close();
      } catch (error) {
        Swal.close();
        this.snackbar = true;
        this.text =
          "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
          error.data.code +
          " - <b>Error:</b> " +
          error.data.message +
          "</p>";
        this.color = "error";
      }
    },

    async loadLogs() {
      this.loading = true;
      this.logsErrores = [];
      const dateFrom = this.startDate || "";
      const dateTo   = this.endDate   || "";


         this.$axiosApi
          .getByCriteria("auditLogSearch", `${dateFrom}/${dateTo}`)
          .then((response) => {

            console.log(response.data);
            this.logsErrores = response.data.data;
            this.loading = false;
          }
        )

          .catch(error => {
            this.loading = false;
            this.$handleAxiosError(error);
            return null;
          })

    },

    exportExcel() {
      Swal.fire({
        title: "Buscando información",
        text: "Generando archivo...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const dateFrom = this.startDate || "";
      const dateTo = this.endDate || "";
      const filterErrors = this.showErrorsOnly ? "?errors=true" : "";

      this.$axiosApi
        .getDownload("auditLogExport", `${dateFrom}/${dateTo}${filterErrors}`, { responseType: "blob" })
        .then((response) => {
          Swal.close();

          if (!response || !response.data) {
            Swal.fire("Atención!", "No se pudo obtener el archivo", "info");
            return;
          }


         const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `logs_errores_${this.startDate}_${this.endDate}.xlsx`); // Nombre del archivo.
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => this.$handleAxiosError(error));
    },
  },
  created() {
    this.startDate = this.today;
    this.endDate = this.today;
  },
  mounted() {
    //this.ocultarMenu();
  },
};
</script>

<style scoped>
.wrap-text {
  max-width: 500px;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
.v-data-table {
  table-layout: fixed;
  width: 100%;
}
.filtros-trans {
  margin-bottom: 4px;
}
.table-cell-trans {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left !important;
}
.text-red {
  color: red;
  font-weight: bold;
}
.rounded-dialog {
  border-radius: 12px !important;
}

.status-chip {
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  text-align: center;
}

.status-ok {
  background-color: #1976D2;
}
.status-error {
  background-color: #f44336;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}

.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  max-height: calc(80vh - 120px);
}

.dialog-footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  background: white;
  border-top: none;
  box-shadow: none;
}

.small-switch {
  margin-top: -4px;
}

.small-switch ::v-deep(.v-label) {
  font-size: 12px !important;
}

.small-switch ::v-deep(.v-input__control) {
  transform: scale(1);
}
</style>
